import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Us`}</h2>
    <p>{`I am newbie dev who are just trying to scratch the surface of different web technologies.`}</p>
    <p>{`I am passionate in learning about game dev, mobile dev and web development.If you have any query, suggestion or project Feel free to contact us`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      